/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, Icon } from '@mui/material'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { compact, orderBy, uniq } from 'lodash'
import { MainButton } from '../components/styled/buttons'
import { InputRow } from '../components/styled/inputs'
import DtoSamplingPointDecla from '../agri/dto/exploitation/DtoSamplingPointDecla'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import { HelpTooltip } from '../components/styled/texts'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import DtoSandreCode from '../referencials/dto/DtoSandreCode'
import ToastrAction from '../components/toasters/ToastrAction'
import { formatMilliers } from '../../../utils/StringUtil'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import DtoVariousMatType from '../referencials/installations/dto/DtoVariousMatType'
import DtoMatChronicle from '../referencials/installations/dto/DtoMatChronicle'
import { formatDate } from '../../../utils/DateUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../components/styled/dialog'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import SimpleSelect from '../components/forms/SimpleSelect'
import moment from 'moment'
import DtoMatEventsType from '../referencials/materiels/dto/DtoMatEventsType'
import AgriAction from '../agri/actions/AgriAction'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import { ReactComponent as CloseLogo } from '../../../ressources/static/svg/Fermer.svg'
import { ReactComponent as DeleteLogo } from '../../../ressources/static/svg/Poubelle.svg'
import StyledCard from '../components/cards/StyledCard'
import { CHRONICLES_CONSTANTS } from '../agri/constants/AgriConstants'
import { getEvolValue } from '../../../utils/AgriUtils'
import DtoMatEvent from '../referencials/materiels/dto/DtoMatEvent'
import { isLargeWindow } from '../../../utils/LocalStorageUtils'
import { getSetting } from '../../../utils/FormUtils'

const styles = (theme) => ({
    paper: {
        backgroundColor: theme.palette.background,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        margin: '10px auto',
        bottom: 'inherit',
        height: '90vh',
        width: '90vw',
        overflowY: 'auto',
    },
    card: {
        padding: '8px',
        height: '100%',
    },
    selected: {
        padding: '8px',
        backgroundColor: '#CCDCE4',
        height: '100%',
    },
    item: {
        padding: '8px !important',
        display: 'flex',
        alignItems: 'center',
        color: 'black',
        fontWeight: 'bold',
    }
})

const STEP_POINT = 0
const STEP_MATERIEL = 1
const STEP_CHRONICLE = 2
const STEP_EVENT = 3

class ModalNewEntry extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: window?.location?.hash?.includes('/point/') ? STEP_MATERIEL : STEP_POINT,
            chronicles: [],
            consoType: undefined,
            errors: [],
            pumpsIds: [],
            countersIds: [],
            openHelp: false,
            selectedPoint: window?.location?.hash?.includes('/point/') ? props?.installation?.id : undefined,
            selectedMaterial: undefined,
            measureDate: moment().format('YYYY-MM-DD'),
            endDate: undefined,
            value: undefined,
            forceEvent: false,
            noStepMat: false,
        }
    }

    componentDidMount() {
        const { exploitation, installation } = this.props
        this.setState({ chronicles: exploitation.link_chronicles })
        if (installation?.id && window?.location?.hash?.includes('/point/')) {
            this.setSelectedPoint(installation.id, true)
        }
    }

    previousStep = () => {
        const { step, forceEvent, noStepMat } = this.state
        if (step === STEP_CHRONICLE && noStepMat) {
            this.setState({ step: STEP_POINT, openHelp: false, noStepMat: false })
        } else {
            this.setState({ step: forceEvent && step !== STEP_MATERIEL ? STEP_MATERIEL : step - 1, openHelp: false, noStepMat: false })
        }
    }

    nextStep = () => {
        const { step } = this.state
        if (this.checkStep(step)) {
            this.setState({ step: step + 1, openHelp: false })
        }
    }

    getCard = (color = 'white', title, elements = [], footer) => (
        <StyledCard
            subTitle={title}
            styleSubTitle={{ backgroundColor: color }}
            styleContainer={{ padding: 0, borderTop: '1px solid black' }}
            styleContent={{ padding: 0 }}
        >
            {elements.map((e, i) => (
                <Grid container style={{ padding: 10, borderTop: i === 0 ? '' : '1px solid black', backgroundColor: color }}>
                    <Grid item xs={6} className='bold' style={{ color: 'grey' }}>{e.title}</Grid>
                    <Grid item xs={6} className='bold'>{e.value || '-'}</Grid>
                </Grid>
            ))}
            {footer}
        </StyledCard>
    )

    checkStep = (step) => {
        const { consoType, measureDate, endDate, value, selectedMaterial } = this.state
        const newErrors = []
        switch (step) {
            case STEP_MATERIEL:
                if (!selectedMaterial) {
                    ToastrAction.error('Veuillez sélectionner un équipement')
                    return false
                }
                this.setState({ errors: newErrors })
                return true
            case STEP_CHRONICLE:
                if (!measureDate) {
                    newErrors.push('measureDate')
                }
                if (isNaN(parseInt(value))) {
                    newErrors.push('value')
                }
                if (consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM && !endDate) {
                    newErrors.push('endDate')
                }
                if (newErrors.length) {
                    this.setState({ errors: newErrors })
                    return false
                }
                this.setState({ errors: newErrors })
                return true
            default:
                this.setState({ errors: newErrors })
                return true
        }
    }

    setSelectedMat = (materiel) => {
        const { forceEvent } = this.state
        const materielsTypeForIndex = getSetting(this.props.applicationSettings, 'materielsTypeForIndex')?.split(',').map((id) => Number(id)) || []
        this.setState({
            selectedMaterial: materiel.id,
            consoType: materielsTypeForIndex?.includes(materiel.materielType) ? CHRONICLES_CONSTANTS.TYPE_INDEX : CHRONICLES_CONSTANTS.TYPE_ESTIM,
            endDate: undefined,
            value: undefined,
        }, () => this.setState({ step: forceEvent ? STEP_EVENT : STEP_CHRONICLE, openHelp: false }))
    }

    getMateriels = (pointId) => {
        const { variousMatSituations, variousMateriels, exploitation } = this.props
        const pointPumps = variousMatSituations.filter((m) => (!m.situationDate || (m.situationDate < moment().valueOf())) && (!m.situationEndDate || (m.situationEndDate > moment().valueOf())) &&
            m.siteType === 7 &&
            m.siteCode === pointId
        ) || []
        const pointCounters = variousMatSituations.filter((m) => (!m.situationDate || (m.situationDate < moment().valueOf())) && (!m.situationEndDate || (m.situationEndDate > moment().valueOf())) &&
            m.siteType === 8 &&
            pointPumps.find(({ idVarious }) => idVarious === m.siteCode)
        ) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const materiels = variousMateriels.filter((m) => [...pumpsIds, ...countersIds].includes(m.id) && (!hasValue(m.administrator) || m.administrator === exploitation.operatorCode))
        return { pumpsIds, countersIds, materiels }
    }

    setSelectedPoint = (pointId, onMount) => {
        const { pumpsIds, countersIds, materiels } = this.getMateriels(pointId)
        const exploitationMatIds = materiels.map(({ id }) => id)
        const pumpsIdsFiltered = uniq(pumpsIds.filter((id) => exploitationMatIds.includes(id)))
        const countersIdsFiltered = uniq(countersIds.filter((id) => exploitationMatIds.includes(id)))
        this.setState({
            selectedPoint: pointId,
            pumpsIds: pumpsIdsFiltered,
            countersIds: countersIdsFiltered,
            selectedMaterial: undefined,
            consoType: undefined,
            endDate: undefined,
            value: undefined,
        }, () => {
            if (!onMount) {
                this.setState({ step: STEP_MATERIEL, openHelp: false })
            }
        })
    }


    getLastChronicle = (point) => {
        const { exploitation } = this.props
        const { materiels } = this.getMateriels(point.id)
        const pointChronicles = exploitation.link_chronicles.filter(({ idMat, measureDate }) => materiels.map(({ id }) => id).includes(idMat) &&
            (!point.startDate || measureDate >= point.startDate) &&
            (!point.endDate || measureDate < point.endDate)
        )
        const lastChronicle = pointChronicles.length ? pointChronicles.reduce((max, chronique) => (max.measureDate > chronique.measureDate) ? max : chronique) : undefined
        if (lastChronicle) {
            const matFound = materiels.find((m) => m.id === lastChronicle.idMat) || {}
            return (
                <>
                    {lastChronicle.measureType === 1 ? (
                        <>
                            <b>{matFound.reference || i18n.unknown}</b>
                            <span>{` - ${formatDate(lastChronicle.measureDate)} -> ${formatDate(lastChronicle.endDate)} : ${formatMilliers(lastChronicle.value) || 0} m3`}</span>
                        </>
                    ) : (
                        <>
                            <b>{matFound.reference || i18n.unknown}</b>
                            <span>{` - ${formatDate(lastChronicle.measureDate)} : ${formatMilliers(lastChronicle.value) || 0}`}</span>
                        </>
                    )}
                </>
            )
        }
        return <span>{i18n.noConsumptionIndicated}</span>
    }

    getPoints = () => {
        const { installations, exploitation, citiesIndex } = this.props
        const { selectedPoint } = this.state
        return compact(exploitation.link_samplings.map((linkPoint) => {
            const point = installations.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const city = citiesIndex[point.townCode] || {}
                const color = selectedPoint === point.id ? '#CCDCE4' : 'white'
                return (
                    <Grid item xs={12} md={3} className='clickable padding-top-2' key={point.id} onClick={() => this.setSelectedPoint(point.id)}>
                        {this.getCard(color, `${i18n.id} : ${point?.code || ''}`, [{
                            title: i18n.name,
                            value: point?.name,
                        }, {
                            title: i18n.city,
                            value: city?.name,
                        }, {
                            title: i18n.localisation,
                            value: point?.location,
                        }, {
                            title: i18n.codeParcelle,
                            value: `${point.parcel || ''} ${point.section || ''}`,
                        }], (
                            <Grid container style={{ padding: 10, borderTop: '1px solid black', backgroundColor: color }}>
                                <Grid item xs={12}>{this.getLastChronicle(point)}</Grid>
                            </Grid>
                        ))}
                    </Grid>
                )
            }
            return null
        }))
    }

    getPumps = () => {
        const { variousMateriels, variousMatSituations, codesSandre, variousMatTypes, matEventsExploitation } = this.props
        const { selectedMaterial, pumpsIds } = this.state
        return pumpsIds.map((pumpId) => {
            const pompeSituation = variousMatSituations.find((m) => m.siteType === 8 && m.siteCode === pumpId)
            const pompeMat = variousMateriels.find((m) => m.id === pumpId) || {}
            const pompe = pompeMat.pump || {}
            const pompeInfos = {
                ...pompeSituation,
                ...pompeMat,
                ...pompe,
            }
            const lastEvent = orderBy(matEventsExploitation.filter((e) => e.idMateriel === pumpId), 'eventDate', 'desc')[0]
            const pumpNature = variousMatTypes.find((t) => t.materielType === pompeInfos.materielType)
            const pumpCategory = codesSandre.find((c) => c.field === 'MAT.MOBILITE' && c.code === pompeInfos.mobilityCode)
            const pumpType = codesSandre.find((c) => c.field === 'POMPES.TYPE' && c.code === pompeInfos.pumpType)
            const color = selectedMaterial === pompeInfos.id ? '#CCDCE4' : 'white'
            return {
                isDown: [2, 4].includes(lastEvent?.idType),
                id: pumpId,
                content: (
                    <Grid item xs={12} md={3} className='clickable padding-top-2' key={pumpId} onClick={() => this.setSelectedMat(pompeInfos)}>
                        {this.getCard(color, `${i18n.id} : ${pompeInfos.reference || ''}`, [{
                            title: i18n.name,
                            value: pompeInfos?.name,
                        }, {
                            title: i18n.nature,
                            value: pumpNature?.name,
                        }, {
                            title: i18n.categ,
                            value: pumpCategory?.name,
                        }, {
                            title: i18n.type,
                            value: pumpType?.name,
                        }, {
                            title: i18n.debitMax,
                            value: pompeInfos.maxFlow ? `${pompeInfos.maxFlow} m3/h` : '-',
                        }, {
                            title: i18n.debitFonctionnement,
                            value: pompeInfos.operatingFlow ? `${pompeInfos.operatingFlow} m3/h` : '-',
                        }])}
                    </Grid>
                )
            }
        })
    }

    getCounters = () => {
        const { variousMateriels, variousMatTypes, matEventsExploitation } = this.props
        const { selectedMaterial, countersIds } = this.state
        return countersIds.map((counterId) => {
            const matFind = variousMateriels.find((c) => c.id === counterId) || {}
            const counter = matFind.counter || {}
            const compteurInfos = {
                ...matFind,
                ...counter,
            }
            const lastEvent = orderBy(matEventsExploitation.filter((e) => e.idMateriel === counterId), 'eventDate', 'desc')[0]
            const counterType = variousMatTypes.find((t) => t.materielType === compteurInfos.materielType)
            const color = selectedMaterial === compteurInfos.id ? '#CCDCE4' : 'white'
            return {
                isDown: [2, 4].includes(lastEvent?.idType),
                id: counterId,
                content: (
                    <Grid item xs={12} md={3} className='clickable padding-top-2' key={counterId} onClick={() => this.setSelectedMat(compteurInfos)}>
                        {this.getCard(color, `${i18n.id} : ${compteurInfos.reference || ''}`, [{
                            title: i18n.name,
                            value: compteurInfos?.name,
                        }, {
                            title: i18n.type,
                            value: counterType?.name,
                        }, {
                            title: i18n.coefLecture,
                            value: compteurInfos?.readingCoefficient,
                        }, {
                            title: i18n.marque,
                            value: compteurInfos?.brand,
                        }, {
                            title: i18n.installationDate,
                            value: compteurInfos?.installationDate,
                        }, {
                            title: i18n.revisionDate,
                            value: compteurInfos?.revisionDate,
                        }])}
                    </Grid>
                )
            }
        })
    }

    handleChange = (key, value) => {
        if (key === 'consoType') {
            this.setState({ endDate: undefined, value: undefined })
        }
        this.setState({ [key]: value })
    }

    getChronicles = (readingCoefficient = 1) => {
        const { classes } = this.props
        const { chronicles, consoType, selectedMaterial } = this.state
        const filteredChronicles = chronicles.filter((c) => c.idMat === selectedMaterial && c.mode !== 'd' && c.measureType === consoType)
        const orderedChronicles = orderBy(filteredChronicles, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
        return (
            <Grid
                container
                alignItems='center'
                justifyContent='space-between'
                style={{ marginTop: '16px' }}
            >
                {consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM ? (
                    <>
                        <Grid item className='italic' xs={3} style={{ textAlign: 'start' }}>
                            {i18n.startDate }
                        </Grid>
                        <Grid item className='italic' xs={3} style={{ textAlign: 'start' }}>
                            {i18n.endDate }
                        </Grid>
                        <Grid item className='italic' xs={3} style={{ textAlign: 'end' }}>
                            {i18n.estimateM3 }
                        </Grid>
                        <Grid item className='italic' xs={2} style={{ textAlign: 'end' }}>
                            {'Cumul en m3'}
                        </Grid>
                        <Grid item className='italic' xs={1} style={{ textAlign: 'end' }} />
                    </>
                ) : (
                    <>
                        <Grid item className='italic' xs={3} style={{ textAlign: 'start' }}>
                            {i18n.statementDate}
                        </Grid>
                        <Grid item className='italic' xs={3} style={{ textAlign: 'end' }}>
                            {i18n.index}
                        </Grid>
                        <Grid item className='italic' xs={4} style={{ textAlign: 'end' }}>
                            {`${i18n.evolution} en m3`}
                        </Grid>
                        <Grid item className='italic' xs={1} style={{ textAlign: 'end' }} />
                    </>
                )}
                {orderedChronicles.map((chronicle, i) => {
                    const valueEvol = i !== filteredChronicles.length - 1 ? getEvolValue(consoType, orderedChronicles, chronicle, i) : null
                    if (consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM) {
                        return (
                            <>
                                {i === 0 && <Grid item className xs={12} style={{ borderBottom: '1px solid black' }} />}
                                <Grid item xs={3} className='bold' style={{ textAlign: 'start', paddingLeft: 0 }}>
                                    {formatDate(chronicle.measureDate)}
                                </Grid>
                                <Grid item xs={3} className='bold' style={{ textAlign: 'start' }}>
                                    {chronicle.endDate ? formatDate(chronicle.endDate) : ''}
                                </Grid>
                                <Grid item xs={3} className='bold' style={{ textAlign: 'end' }}>
                                    {`${formatMilliers(chronicle.value) || 0} m3`}
                                </Grid>
                                <Grid item xs={2} className='bold' style={valueEvol < 0 ? { color: 'orange', textAlign: 'end' } : { textAlign: 'end' }}>
                                    {hasValue(valueEvol) ? formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0)) : ''}
                                </Grid>
                                <Grid item xs={1} className={classes.item}>
                                    <DeleteLogo className='clickable' fill='red' style={{ height: 25 }} onClick={() => this.removeChronicle(chronicle.idChronicle)} />
                                </Grid>
                                <Grid item className xs={12} style={{ borderBottom: '1px solid black' }} />
                            </>
                        )
                    }
                    return (
                        <>
                            {i === 0 && <Grid item className xs={12} style={{ borderBottom: '1px solid black' }} />}
                            <Grid item xs={3} className='bold' style={{ textAlign: 'start', paddingLeft: 0 }}>
                                {formatDate(chronicle.measureDate)}
                            </Grid>
                            <Grid item xs={3} className='bold' style={{ textAlign: 'end' }}>
                                {formatMilliers(chronicle.value) || 0}
                            </Grid>
                            <Grid item xs={4} className='bold' style={valueEvol < 0 ? { color: 'orange', textAlign: 'end' } : { textAlign: 'end' }}>
                                {hasValue(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0))}` : ''}
                            </Grid>
                            <Grid item xs={1} className={classes.item}>
                                <DeleteLogo className='clickable' fill='red' style={{ height: 25 }} onClick={() => this.removeChronicle(chronicle.idChronicle)} />
                            </Grid>
                            <Grid item className xs={12} style={{ borderBottom: '1px solid black' }} />
                        </>
                    )
                })}
            </Grid>
        )
    }

    removeChronicle = (idChronicle) => {
        const { chronicles } = this.state
        const newChronicles = [
            ...chronicles.filter((c) => c.idChronicle !== idChronicle),
            {
                ...chronicles.find((c) => c.idChronicle === idChronicle),
                mode: 'd',
            }
        ]
        this.setState({ chronicles: newChronicles })
    }

    addChronicle = () => {
        const {
            measureDate,
            endDate,
            value,
            chronicles,
            consoType,
            selectedMaterial,
        } = this.state
        if (this.checkStep(STEP_CHRONICLE)) {
            const id = chronicles.length ? Math.max(...chronicles.map(c => c.idChronicle)) + 1 : 0
            this.setState({
                chronicles: [
                    ...chronicles,
                    {
                        matType: 'divers',
                        idMat: selectedMaterial,
                        measureType: consoType,
                        measureDate: new Date(measureDate).getTime(),
                        value: value,
                        endDate: consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM ? new Date(endDate).getTime() : undefined,
                        idChronicle: id,
                        new: true,
                    }
                ],
                measureDate: consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM ? endDate : '',
                value: '',
                endDate: '',
            })
        }
    }

    getContent = (step) => {
        const { variousMateriels, matEventsTypes } = this.props
        const { errors, consoType, measureDate, endDate, value, selectedMaterial, idType, comment, forceEvent } = this.state
        const matFind = variousMateriels.find((mat) => mat.id === selectedMaterial) || {}
        const pumpsData = this.getPumps()
        const countersData = this.getCounters()
        switch (step) {
            case STEP_POINT:
                const content = this.getPoints()
                if (content.length === 1) {
                    this.setSelectedPoint(Number(content[0].key))
                    return null
                }
                return (
                    <>
                        <h3>
                            {i18n.selectPoint}
                        </h3>
                        <Grid container spacing={1} alignItems='stretch'>
                            {this.getPoints()}
                        </Grid>
                    </>
                )
            case STEP_MATERIEL:
                const pumpsInService = pumpsData.filter((p) => !p.isDown)
                const countersInService = countersData.filter((c) => !c.isDown)
                if (countersInService.length === 1 && !forceEvent) {
                    this.setState({ selectedMaterial: countersData[0].id, step: STEP_CHRONICLE, consoType: CHRONICLES_CONSTANTS.TYPE_INDEX, noStepMat: true })
                    return null
                }
                if (pumpsInService.length === 1 && !forceEvent) {
                    this.setState({ selectedMaterial: pumpsData[0].id, step: STEP_CHRONICLE, consoType: CHRONICLES_CONSTANTS.TYPE_ESTIM, noStepMat: true })
                    return null
                }
                return (
                    <>
                        <h3>
                            {i18n.selectMaterial}<br />
                        </h3>
                        <Grid container spacing={1} alignItems='stretch'>
                            {[...(forceEvent ? pumpsData : pumpsInService).map((p) => p.content), ...(forceEvent ? countersData : countersInService).map((c) => c.content)]}
                        </Grid>
                    </>
                )
            case STEP_CHRONICLE:
                const isEstim = consoType === CHRONICLES_CONSTANTS.TYPE_ESTIM
                return (
                    <>
                    <Grid container>
                        <Grid item xs={8}>
                            <h3>
                                {isEstim ? i18n.seizureEstim : i18n.seizureIndex}<br />
                                {consoType === CHRONICLES_CONSTANTS.TYPE_INDEX ? `${i18n.coefLecture} : ${matFind?.counter?.readingCoefficient || ''}` : ''}
                            </h3>
                        </Grid>
                        <Grid item xs={4}>
                            <MainButton
                                little
                                onClick={() => {
                                    this.setState({ step: [...pumpsData, ...countersData].length > 1 ? STEP_MATERIEL : STEP_EVENT, forceEvent: [...pumpsData, ...countersData].length > 1 })
                                    this.handleChange('consoType', CHRONICLES_CONSTANTS.TYPE_EVENT)
                                }}
                            >
                                {i18n.reportIncident}
                            </MainButton>
                        </Grid>
                    </Grid>
                        <StyledCard styleContainer={{ padding: 0, borderTop: '1px solid black' }}>
                            <InputRow
                                id='startDate'
                                label={isEstim ? i18n.startDate : i18n.statementDate}
                                type='date'
                                required
                                variant='outlined'
                                value={measureDate}
                                inputProps={{
                                    max: endDate || '9999-12-31'
                                }}
                                onChange={(e) => this.handleChange('measureDate', e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={errors.find((e) => e === 'measureDate')}
                            />
                            {isEstim && (
                                <InputRow
                                    id='endDate'
                                    label={i18n.endDate}
                                    type='date'
                                    inputProps={{
                                        min: measureDate,
                                        max: '9999-12-31'
                                    }}
                                    required
                                    variant='outlined'
                                    value={endDate}
                                    onChange={(e) => this.handleChange('endDate', e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={errors.find((e) => e === 'endDate')}
                                />
                            )}
                            <InputRow
                                id='value'
                                label={consoType === 1 ? i18n.estim : i18n.index}
                                type='number'
                                value={value}
                                onChange={(e) => this.handleChange('value', parseInt(e.target.value))}
                                variant='outlined'
                                required
                                error={errors.find((e) => e === 'value')}
                            />
                            <MainButton little onClick={() => this.addChronicle()}>
                                {i18n.add}
                            </MainButton>
                        </StyledCard>
                        {this.getChronicles(matFind?.counter?.readingCoefficient || 1)}
                    </>
                )
            case STEP_EVENT:
                return (
                    <>
                        <h3>
                            {i18n.reportIncident}<br />
                        </h3>
                        <StyledCard styleContainer={{ padding: 0, borderTop: '1px solid black' }}>
                            <SimpleSelect
                                id='idType'
                                required
                                noNullValue
                                label={i18n.eventType}
                                value={idType}
                                onChange={(v) => this.handleChange('idType', v)}
                                integerValue
                                options={matEventsTypes}
                                keyValue='id'
                            />
                            <InputRow
                                id='measureDate'
                                label={i18n.date}
                                type='date'
                                required
                                variant='outlined'
                                value={moment(measureDate).format('YYYY-MM-DD')}
                                inputProps={{
                                    max: '9999-12-31'
                                }}
                                onChange={(e) => this.handleChange('measureDate', e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <InputRow
                                item
                                id='comment'
                                type='text'
                                variant='outlined'
                                placeholder={i18n.comment}
                                value={comment}
                                onChange={(e) => this.handleChange('comment', e.target.value)}
                                multiline
                                rows={10}
                                rowsMax={Infinity}
                                InputProps={{ style: { minHeight: 'inherit', height: '100%', alignItems: 'flex-start' } }}
                            />
                        </StyledCard>
                    </>
                )
            default:
                return ''
        }
    }

    formatListChronicles = (chronicles) => {
        return chronicles.map((c) => {
            if (c.new) {
                return {
                    ...c,
                    idChronicle: undefined,
                }
            }
            return c
        })
    }

    onSave = () => {
        const { chronicles, step, measureDate, idType, selectedMaterial, comment } = this.state
        const { exploitation } = this.props
        if (step === STEP_EVENT) {
            if (selectedMaterial && idType && measureDate) {
                const eventFormatted = {
                    materielType: 'divers',
                    idMateriel: selectedMaterial,
                    idType,
                    eventDate: new Date(measureDate).getTime(),
                    comment,
                }
                this.props.createMatEvent(eventFormatted).then(() => {
                    this.props.fetchMatEventsByExploitation(exploitation.idExploitation).then(() => {
                        this.setState({ selectedMaterial: undefined, step: STEP_MATERIEL, consoType: undefined, forceEvent: false })
                    })
                })
            } else {
                ToastrAction.warning(i18n.fillAllFields)
            }
        } else {
            const link_chronicles = this.formatListChronicles(chronicles)
            const updateExploitation = {
                ...exploitation,
                link_chronicles,
            }
            this.props.updateExploitation(updateExploitation).then(() => {
                this.props.fetchExploitation().then(() => {
                    this.props.onClose()
                })
            })
        }
    }

    onCancel = () => {
        this.setState({
            step: STEP_POINT,
            chronicles: [],
            measureDate: '',
            value: '',
            endDate: '',
        })
        this.props.onClose()
    }

    toggleHelp = () => {
        const { openHelp } = this.state
        this.setState({ openHelp: !openHelp })
    }

    getContentHelp = () => {
        const { cms } = this.props
        const { step, forceEvent } = this.state
        const cmsFind = cms.find((c) => c.id === (step === STEP_EVENT || forceEvent ? 41 : 40)) || {}
        return cmsFind.comment || ''
    }

    render() {
        const { step, openHelp, selectedPoint, selectedMaterial } = this.state
        const { open, installations, variousMateriels } = this.props
        const pointFind = installations.find((i) => i.id === selectedPoint)
        const matFind = variousMateriels.find((mat) => mat.id === selectedMaterial)
        return (
            <DialogMUI open={open}>
                {open && (
                    <>
                        <DialogTitleMUI>
                            <Grid container justifyContent='space-between' alignItems='center'>
                                <b style={{ padding: 10, fontSize: 21 }}>{i18n.newEntry}</b>
                                <CloseLogo className='clickable' fill='white' style={{ padding: 10, height: 30 }} onClick={this.onCancel} />
                                {pointFind && step !== STEP_POINT && <Grid item xs={12} style={{ borderTop: '1px solid white', padding: 10 }}>{`${i18n.point} ${pointFind.code}`}</Grid>}
                                {matFind && step !== STEP_POINT && step !== STEP_MATERIEL && <Grid item xs={12} style={{ borderTop: '1px solid white', padding: 10 }}>{matFind.reference}{matFind?.counter?.informative && ` (${i18n.informative})`}</Grid>}
                            </Grid>
                        </DialogTitleMUI>
                        <DialogContentMUI style={{ paddingTop: 8, paddingBottom: 16 }}>
                            {this.getContent(step)}
                        </DialogContentMUI>
                        <DialogActionsMUI>
                            <Grid
                                container
                                direction='row'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <Grid item xs={4.5}>
                                    {step !== STEP_POINT && (
                                        <MainButton
                                            onClick={this.previousStep}
                                        >
                                            <Icon>keyboard_arrow_left</Icon>
                                            {i18n.previous}
                                        </MainButton>
                                    )}
                                </Grid>
                                <HelpTooltip
                                    title={(<div dangerouslySetInnerHTML={{ __html: this.getContentHelp() }} />)}
                                    open={openHelp}
                                    placement='top'
                                    arrow
                                    disableHoverListener
                                >
                                    <Grid item xs={2}>
                                        <MainButton onClick={this.toggleHelp}>
                                            {isLargeWindow() ? i18n.help : <Icon>help</Icon>}
                                        </MainButton>
                                    </Grid>
                                </HelpTooltip>
                                <Grid item xs={4.5}>
                                    {![STEP_CHRONICLE, STEP_EVENT].includes(step) ? (
                                        <MainButton
                                            onClick={this.nextStep}
                                            disabled={(step === STEP_POINT && !selectedPoint) || (step === STEP_MATERIEL && !selectedMaterial)}
                                        >
                                            {i18n.next}
                                            <Icon>keyboard_arrow_right</Icon>
                                        </MainButton>
                                    ) : (
                                        <MainButton onClick={this.onSave}>
                                            {i18n.validate}
                                        </MainButton>
                                    )}
                                </Grid>
                            </Grid>
                        </DialogActionsMUI>
                    </>
                )}
            </DialogMUI>
        )
    }
}

ModalNewEntry.propTypes = {
    classes: PropTypes.instanceOf(PropTypes.shape({})),
    point: PropTypes.instanceOf(DtoSamplingPointDecla),
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installation: PropTypes.instanceOf(DtoInstallation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    materiels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    variousMatTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatType)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    chronicles: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatChronicle)),
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    selectedMaterial: PropTypes.number,
    consoType: PropTypes.number,
    fetchInstallationsByExploitationId: PropTypes.func,
    fetchExploitation: PropTypes.func,
    updateExploitation: PropTypes.func,
    createMatEvent: PropTypes.func,
    updateMatEvent: PropTypes.func,
    deleteMatEvent: PropTypes.func,
    fetchMatEventsByExploitation: PropTypes.func,
    matEventsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEventsType)),
    matEventsExploitation: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEvent)),
    match: PropTypes.instanceOf(PropTypes.shape({})),
    applicationSettings: PropTypes.arrayOf(PropTypes.shape({})),
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        installation: store.InstallationsReducer.installation,
        installations: store.InstallationsReducer.installations,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        variousMatTypes: store.InstallationsReducer.variousMatTypes,
        codesSandre: store.ReferencialReducer.codesSandre,
        citiesIndex: store.CityReducer.citiesIndex,
        cms: store.CmsReducer.cms,
        matEventsTypes: store.MaterielReducer.matEventsTypes,
        matEventsExploitation: store.MaterielReducer.matEventsExploitation,
        applicationSettings: store.HomeReducer.applicationSettings,
    }
}

const mapDispatchToProps = {
    fetchInstallationsByExploitationId: InstallationsAction.fetchInstallationsByExploitationId,
    fetchExploitation: AgriAction.fetchExploitation,
    updateExploitation: AgriAction.updateExploitation,
    createMatEvent: MaterielAction.createMatEvent,
    updateMatEvent: MaterielAction.updateMatEvent,
    deleteMatEvent: MaterielAction.deleteMatEvent,
    fetchMatEventsByExploitation: MaterielAction.fetchMatEventsByExploitation,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModalNewEntry))
